.Warning {
	color: red;
	font-weight: bold;
	font-size: 24px;
}

.subtitle {
	font-size: 18px;
	text-align: justify;
}

.gap_above {
	margin-top: 50px;
}

.gap_above_large {
	margin-top: 80px;
}

.gap_below {
	margin-bottom: 50px;
}

.gap_above_small {
	margin-top: 25px;
}

.left {
	text-align: left;
}

.react-contextmenu {
	background-color: #fff;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 0.25rem;
	color: #373a3c;
	font-size: 16px;
	margin: 2px 0 0;
	min-width: 160px;
	outline: none;
	opacity: 0;
	padding: 5px 0;
	pointer-events: none;
	text-align: left;
	transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
	opacity: 1;
	pointer-events: auto;
	z-index: 9999;
}

.react-contextmenu-item {
	background: 0 0;
	border: 0;
	color: #373a3c;
	cursor: pointer;
	font-weight: 400;
	line-height: 1.5;
	padding: 3px 20px;
	text-align: inherit;
	white-space: nowrap;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
	color: #fff;
	background-color: #20a0ff;
	border-color: #20a0ff;
	text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
	background-color: transparent;
	border-color: rgba(0, 0, 0, 0.15);
	color: #878a8c;
}

.react-contextmenu-item--divider {
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	cursor: inherit;
	margin-bottom: 3px;
	padding: 2px 0;
}
.react-contextmenu-item--divider:hover {
	background-color: transparent;
	border-color: rgba(0, 0, 0, 0.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
	padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
	content: "▶";
	display: inline-block;
	position: absolute;
	right: 7px;
}

.example-multiple-targets::after {
	content: attr(data-count);
	display: block;
}

.glyphicon {
	font-size: 30px;
}

@media screen and (max-width: 900px) {
	body {
		margin-top: 50px;
	}
}

body {
	margin-top: 50px;
	margin-bottom: 50px;
}

.h2,
h2 {
	font-size: 24px;
}

.h3,
h3 {
	font-size: 18px;
}

.h4,
h4 {
	font-size: 16px;
}

.Collapsible {
	background-color: white;
}

.Collapsible__contentInner {
	padding: 10px;
	border: 1px solid #ebebeb;
	border-top: 0;
}
.Collapsible__contentInner p {
	margin-bottom: 10px;
	font-size: 14px;
	line-height: 20px;
}
.Collapsible__contentInner p:last-child {
	margin-bottom: 0;
}

.Collapsible__trigger {
	display: block;
	font-weight: 400;
	text-decoration: none;
	color: #333333;
	position: relative;
	border: 1px solid white;
	padding: 10px;
	background: #fff;
	color: black;
}
.Collapsible__trigger:after {
	font-family: "FontAwesome";
	font-size: large;
	content: "\f107";
	position: absolute;
	right: 10px;
	top: 10px;
	display: block;
	transition: transform 300ms;
}
.Collapsible__trigger.is-open:after {
	transform: rotateZ(180deg);
}
.Collapsible__trigger.is-disabled {
	opacity: 0.5;
	background-color: grey;
}

.CustomTriggerCSS {
	background-color: #fff;
	transition: background-color 200ms ease;
}

.CustomTriggerCSS--open {
	background-color: darkslateblue;
}

.Collapsible__custom-sibling {
	padding: 5px;
	font-size: 12px;
	background-color: #cbb700;
	color: black;
}

.popover .arrow {
    display: none;
}

.fit {
	width: -moz-fit-content;
	width: fit-content;
}